import React, { useState } from "react";
import styles from "./home.module.css";
import {
  collection,
  addDoc,
  getFirestore,
  query,
  getDocs,
  writeBatch,
  doc,
} from "firebase/firestore";
import { app } from "../firebase";
import { useNavigate } from "react-router-dom";

const HomeScreen = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMatchFound, setIsMatchFound] = useState(false);
  const [availableRoomCode, setAvailableRoomCode] = useState("");
  const navigate = useNavigate();

  const [otherUserData, setOtherUserData] = useState();

  return (
    <div>
      <h1>Welcome, {user.displayName}</h1>
      <div className="flex flex-row justify-between">
        <Card user={user} />
        {otherUserData != null ? <Card user={otherUserData} /> : <div></div>}
      </div>

      <div className="flex flex-row justify-center ">
        {isMatchFound ? (
          <button className={styles.cardprimarybutton} onClick={async () => {}}>
            Join the call
          </button>
        ) : (
          <button
            className={styles.cardprimarybutton}
            onClick={async () => {
              setIsLoading(true);
              console.log("Check click");
              console.log("Button click");

              const db = getFirestore(app);

              try {
                // Add the current user to Firebase available
                const docRef = await addDoc(collection(db, "available"), {
                  name: user.displayName,
                  email: user.email,
                  photoURL: user.photoURL,
                  timestamp: Date.now(),
                });
                console.log("Document written with ID: ", docRef.id);
                // Pair the current user with another user in the available collection

                const availableUsersQuery = query(collection(db, "available"));
                const availableUsersSnapshot = await getDocs(
                  availableUsersQuery
                );

                // Check if there are other available users
                if (availableUsersSnapshot.size > 1) {
                  // Exclude the current user from the list of available users
                  const otherAvailableUsers =
                    availableUsersSnapshot.docs.filter(
                      (doc) => doc.data().email !== user.email
                    );

                  // If there are other available users, randomly select one to pair with the current user
                  if (otherAvailableUsers.length > 0) {
                    const randomIndex = Math.floor(
                      Math.random() * otherAvailableUsers.length
                    );

                    const otherUser = otherAvailableUsers[randomIndex].data();

                    // Add both the current user and the other user to the "matched" collection
                    const matchedUsersRef = collection(db, "matched");
                    const batch = writeBatch(db);

                    const currentUserDocRef = doc(matchedUsersRef, user.email);
                    const otherUserDocRef = doc(
                      matchedUsersRef,
                      otherUser.email
                    );

                    batch.set(currentUserDocRef, {
                      name: user.displayName,
                      email: user.email,
                      photoURL: user.photoURL,
                      matchedWithEmail: otherUser.email,
                      timestamp: Date.now(),
                    });

                    batch.set(otherUserDocRef, {
                      name: otherUser.name,
                      email: otherUser.email,
                      photoURL: otherUser.photoURL,
                      matchedWithEmail: user.email,
                      timestamp: Date.now(),
                    });

                    await batch.commit();

                    setIsLoading(false);
                    setIsMatchFound(true);
                    console.log("Users matched successfully!");
                    console.log(user);
                    console.log(otherUser);

                    setOtherUserData(otherUser);

                    const apiUrl =
                      "https://vibeoverweekendserver-production.up.railway.app/api";

                    try {
                      const response = await fetch(apiUrl, {
                        method: "GET",
                      });

                      if (!response.ok) {
                        throw new Error(
                          `HTTP error! status: ${response.status}`
                        );
                      }

                      const data = await response.json();
                      const code = data.roomCode.data[0].code;
                      setAvailableRoomCode(code);
                      navigate("/call", { state: { roomCode: code } });
                    } catch (error) {
                      console.error("Error getting a room", error);
                    }
                  } else {
                    setIsLoading(false);
                    alert("No other available users to pair with.");
                    console.log("No other available users to pair with.");
                  }
                } else {
                  setIsLoading(false);
                  alert("No other available users to pair with.");
                  console.log("No other available users to pair with.");
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {isLoading ? "Looking for a match.." : "Find a match"}
          </button>
        )}
      </div>
    </div>
  );
};

export default HomeScreen;

const Card = ({ user }) => {
  const originalPhotoURL = user.photoURL.replace(/\/s\d+-c\//, "/");
  return (
    <div className={styles.card}>
      <div className={styles.cardcontent}>
        <img
          src={originalPhotoURL}
          alt={user.displayName != null ? user.displayName : user.name}
          className="object-cover w-48 h-48"
        />
        <div className="divide-y divide-gray-300/50">
          <h2 className="text-xl font-semibold mb-2">
            {user.displayName != null ? user.displayName : user.name}
          </h2>
          <p className="text-sm text-gray-500">{user.email}</p>
        </div>
      </div>
    </div>
  );
};
