import "../App.css";
import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const AuthenticationScreen = () => {
  const navigate = useNavigate();

  const signInUsingFirebase = async () => {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };

    const app = initializeApp(firebaseConfig);

    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log(user);

        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("Error in Firebase sign in: " + errorCode);
        console.log("Error in Firebase sign in: " + errorMessage);
      });
  };

  return (
    <div>
      <>
        <div className="flex flex-col items-center justify-center min-h-screen gap-8 px-4 md:px-6">
          <div className="space-y-4 text-center">
            <h1 className="text-3xl font-bold sm:text-4xl md:text-5xl">
              Vibe Over Weekend
            </h1>
            <p className="text-gray-500 md:text-xl dark:text-gray-400">
              Connect with people over the weekend
            </p>
          </div>
          <button
            onClick={async () => {
              const user = await signInUsingFirebase();
              console.log("Signed in User: " + user);
            }}
          >
            Get started with Google
          </button>
        </div>
        <footer className="bg-gray-100 py-4 w-full dark:bg-gray-800">
          <div className="container px-4 md:px-6 flex items-center justify-between">
            <p className="text-xs text-gray-500 dark:text-gray-400">
              © 2024 Vibe Over Weekend
            </p>
          </div>
        </footer>
      </>
    </div>
  );
};

export default AuthenticationScreen;
