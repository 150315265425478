// App.js
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AuthenticationScreen from "./auth/authentication";
import HomeScreen from "./home/home";
import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./App.css";
import CallScreen from "./call/call";

function App() {
  const [user, setUser] = useState(null);
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, [firebaseConfig]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={user ? <HomeScreen user={user} /> : <Navigate to="/login" />}
        />
        <Route
          path="/call"
          element={user ? <CallScreen /> : <Navigate to="/login" />}
        />
        <Route path="/login" element={<AuthenticationScreen />} />
      </Routes>
    </Router>
  );
}

export default App;
