import { HMSPrebuilt } from "@100mslive/roomkit-react";
import React from "react";
import { useLocation } from "react-router-dom";

const CallScreen = () => {
  const location = useLocation();
  const { roomCode } = location.state || { roomCode: "default-room-code" };

  return (
    <div style={{ height: "100vh" }}>
      <HMSPrebuilt roomCode={"jwc-ecp-tuy"} />
    </div>
  );
};

export default CallScreen;
